import { useState, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

export default function App() {
  const [cards, setCards] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [filteredChecklist, setFilteredChecklist] = useState([]);

  const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [sports, setSports] = useState([]);
  const [products, setProducts] = useState([]);

  const [brand, setBrand] = useState("");
  const [year, setYear] = useState("");
  const [sport, setSport] = useState("");
  const [product, setProduct] = useState("");

  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [sets, setSets] = useState([]);
  const [positions, setPositions] = useState([]);

  const [team, setTeam] = useState("All");
  const [player, setPlayer] = useState("All");
  const [set, setSet] = useState("Base");
  const [position, setPosition] = useState("All");

  const [view, setView] = useState("Grid");

  useEffect(() => {
    fetch('https://checklistchamp.com/sets.php')
    .then(function(u){ 
        return u.json();
    })
    .then(function(json) {
      setCards(json);

      let brandList: any = [];
      for (let i = 0; i < json.length; i++) {
        if (!brandList.includes(json[i]['brand'])) {
          brandList.push(json[i]['brand']);
        }
      }

      setBrands(brandList);
      selectBrand(brandList[0], json);
    });
  }, []);

  const selectBrand = (brand: string, cards: any) => {
    setBrand(brand);

    let yearList: any = [];
    for (let i = 0; i < cards.length; i++) {
      if (cards[i]['brand'] === brand && !yearList.includes(cards[i]['year'])) {
        yearList.push(cards[i]['year']);
      }
    }

    setYears(yearList);
    selectYear(yearList[0], brand, cards);
  }

  const selectYear = (year: string, brand: string, cards: any) => {
    setYear(year);

    let sportList: any = [];
    for (let i = 0; i < cards.length; i++) {
      if (cards[i]['brand'] === brand && cards[i]['year'] === year && !sportList.includes(cards[i]['sport'])) {
        sportList.push(cards[i]['sport']);
      }
    }

    setSports(sportList);
    selectSport(sportList[0], year, brand, cards);
  }

  const selectSport = (sport: string, year: string, brand: string, cards: any) => {
    setSport(sport);

    let productList: any = [];
    for (let i = 0; i < cards.length; i++) {
      if (cards[i]['brand'] === brand && cards[i]['year'] === year && cards[i]['sport'] === sport && !productList.includes(cards[i]['program'])) {
        productList.push(cards[i]['program']);
      }
    }

    setProducts(productList);
    selectProduct(productList[0], sport, year, brand);
  }

  const selectProduct = (product: string, sport: string, year: string, brand: string) => {
    setProduct(product);

    fetch('https://checklistchamp.com/cards.php?sport=' + sport + '&year=' + year + '&brand=' + brand + '&program=' + product)
    .then(function(u){ 
        return u.json();
    })
    .then(function(json) {
      let cards = json;

      let cardList: any = [];
      let teamList: any = [];
      let playerList: any = [];
      let setList: any = [];
      let positionList: any = [];

      for (let i = 0; i < cards.length; i++) {
        cardList.push(cards[i]);

        if (!teamList.includes(cards[i]['team'])) {
          teamList.push(cards[i]['team']);
        }
        if (!playerList.includes(cards[i]['athlete'])) {
          playerList.push(cards[i]['athlete']);
        }
        if (!setList.includes(cards[i]['card_set'])) {
          setList.push(cards[i]['card_set']);
        }
        if (!positionList.includes(cards[i]['position'])) {
          positionList.push(cards[i]['position']);
        }
      }

      setChecklist(cardList);
      
      teamList.sort();
      playerList.sort();
      setList.sort();
      positionList.sort();
  
      setTeams(teamList);
      setPlayers(playerList);
      setSets(setList);
      setPositions(positionList);
      
      setTeam("All");
      setPlayer("All");
      setSet("All");
      setPosition("All");
  
      filterChecklist(cardList, "All", "All", "All", "All");
    });
  }

  const filterChecklist = (checklist: any, team: any, player: any, set: any, position: any) => {
    let filteredChecklist: any = [];

    for (let i = 0; i < checklist.length; i++) {
      if ((team == "All" || team == checklist[i]['team']) && (player == "All" || player == checklist[i]['athlete']) && (set == "All" || set == checklist[i]['card_set']) && (position == "All" || position == checklist[i]['position'])) {
        filteredChecklist.push(checklist[i]);
      }
    }

    setFilteredChecklist(filteredChecklist);
  }

  return (
    <div className="wrapper">
        <Navbar className="desktop" sticky="top" style={{ gap: 10, backgroundColor: '#d9ebef' }}>
          <img src="/assets/img/checklist_champ_logo.png" height="40" />
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={"Brand: " + brand}>
            {brands.map((brand: any) => <Dropdown.Item onClick={() => { selectBrand(brand, cards); }} key={brand}>{brand}</Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={"Year: " + year}>
            {years.map((year: any) => <Dropdown.Item onClick={() => { selectYear(year, brand, cards); }} key={year}>{year}</Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={"Sport: " + sport}>
            {sports.map((sport: any) => <Dropdown.Item onClick={() => { selectSport(sport, year, brand, cards); }} key={sport}>{sport}</Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={"Product: " + product}>
            {products.map((product: any) => <Dropdown.Item onClick={() => { selectProduct(product, sport, year, brand); }} key={product}>{product}</Dropdown.Item>)}
          </DropdownButton>
          <div style={{ flexGrow: 1 }}></div>
          <div>
            Powered By
            <img src="/assets/img/scn_logo.png" height="40" />
          </div>
        </Navbar>
        <Navbar className="mobile-flex" sticky="top" style={{ gap: 10, backgroundColor: '#d9ebef' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10, width: '100vw'}}>
            <img src="/assets/img/checklist_champ_logo.png" height="40" />
            <h4 style={{ marginTop: 5,  }}>Checklist Champ</h4>
            <div style={{ flexGrow: 1 }}></div>
            <div>
              Powered By
              <img src="/assets/img/scn_logo.png" height="40" />
            </div>
          </div>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={brand}>
            {brands.map((brand: any) => <Dropdown.Item onClick={() => { selectBrand(brand, cards); }} key={brand}>{brand}</Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={year}>
            {years.map((year: any) => <Dropdown.Item onClick={() => { selectYear(year, brand, cards); }} key={year}>{year}</Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={sport}>
            {sports.map((sport: any) => <Dropdown.Item onClick={() => { selectSport(sport, year, brand, cards); }} key={sport}>{sport}</Dropdown.Item>)}
          </DropdownButton>
          <DropdownButton variant="light" className="top-nav-btn" drop="down" title={product}>
            {products.map((product: any) => <Dropdown.Item onClick={() => { selectProduct(product, sport, year, brand); }} key={product}>{product}</Dropdown.Item>)}
          </DropdownButton>
        </Navbar>
        <div style={{ padding: 20 }}>
          <h2>{year} {brand} {product} {sport}</h2>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, marginBottom: 20 }}>
            <DropdownButton variant="light" drop="down" title={"View: " + view}>
              <Dropdown.Item onClick={() => { setView("Grid"); }}>Grid</Dropdown.Item>
              <Dropdown.Item onClick={() => { setView("By Set"); setTeam("All"); setPosition("All"); setSet("All"); setPosition("All"); }}>By Set</Dropdown.Item>
              <Dropdown.Item onClick={() => { setView("By Team"); setTeam("All"); setPosition("All"); setSet("All"); setPosition("All"); }}>By Team</Dropdown.Item>
            </DropdownButton>
            {view == "Grid" ?
            <>
              <DropdownButton variant="light" drop="down" title={"Team: " + team}>
                <Dropdown.Item onClick={() => { setTeam("All"); filterChecklist(checklist, "All", player, set, position); }}>All</Dropdown.Item>
                {teams.map((team: any) => <Dropdown.Item onClick={() => { setTeam(team); filterChecklist(checklist, team, player, set, position); }} key={team}>{team}</Dropdown.Item>)}
              </DropdownButton>
              <DropdownButton variant="light" drop="down" title={"Player: " + player}>
                <Dropdown.Item onClick={() => { setPosition("All"); filterChecklist(checklist, team, "All", set, position); }}>All</Dropdown.Item>
                {players.map((player: any) => <Dropdown.Item onClick={() => { setPlayer(player); filterChecklist(checklist, team, player, set, position); }} key={player}>{player}</Dropdown.Item>)}
              </DropdownButton>
              <DropdownButton variant="light" drop="down" title={"Set: " + set}>
                <Dropdown.Item onClick={() => { setSet("All"); filterChecklist(checklist, team, player, "All", position); }}>All</Dropdown.Item>
                {sets.map((set: any) => <Dropdown.Item onClick={() => { setSet(set); filterChecklist(checklist, team, player, set, position); }} key={set}>{set}</Dropdown.Item>)}
              </DropdownButton>
              <DropdownButton variant="light" drop="down" title={"Position: " + position}>
                <Dropdown.Item onClick={() => { setPosition("All"); filterChecklist(checklist, team, player, set, "All"); }}>All</Dropdown.Item>
                {positions.map((position: any) => <Dropdown.Item onClick={() => { setPosition(position); filterChecklist(checklist, team, player, set, position); }} key={position}>{position}</Dropdown.Item>)}
              </DropdownButton>
            </> : null }
          </div>
          {view == "Grid" ?
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Set</th>
                <th>Player</th>
                <th>Team</th>
                <th>Position</th>
              </tr>
            </thead>
            <tbody>
              {filteredChecklist.map((card: any) => 
                <tr>
                  <td>{card['card_number']}</td>
                  <td>{card['card_set']}</td>
                  <td>{card['athlete']}</td>
                  <td>{card['team']}</td>
                  <td>{card['position']}</td>
                </tr>
              )}
            </tbody>
          </Table> : null }
          {view == "By Set" ?
          <div>
            {sets.map((set: any) =>
              <div>
                <h5>{set}</h5>
                {filteredChecklist.map((card: any) => 
                  <>
                    {card['card_set'] == set ?
                    <div>
                      {card['card_number']} - {card['athlete']} ({card['team']})
                    </div> : null }
                  </>
                )}
                <br />
              </div>
            )}
          </div> : null }
          {view == "By Team" ?
          <div>
            {teams.map((team: any) =>
              <div>
                <h5>{team}</h5>
                {filteredChecklist.map((card: any) => 
                  <>
                    {card['team'] == team ?
                    <div>
                      {card['card_number']} - {card['card_set']} {card['athlete']}
                    </div> : null }
                  </>
                )}
                <br />
              </div>
            )}
          </div> : null }
        </div>
    </div>
  );
}